// const base_url_ = 'https://yizhigeapi.yizhige.cn' //https://platform.teamcafe.cn
const base_url_ = ''

const AGENCY_NAME = '门店指挥官'
const MAP_KEY = 'FN6BZ-3XMK3-KIE3S-YA6FB-ITHI3-EQFXS'
const COMPANY_ID = 10000
const UPLOAD_BASE_URL = base_url_ + '/saas-biz-service'
const BASE_URL = base_url_ + '/saas-biz-service/manager'
const BASE_URL1 = base_url_ + '/saas-biz-service/manager/admin'
const BASE_URL2 = base_url_ + '/saas-biz-service'
const RESERVE_START_TIME = '10:00'
const FORM_EMPTY_ERROR_TIPS='带*项为必填选项，请正确填写相关数据!'
const SERVE_DEFAULT_IMG='/uploads/202211/10/eaf467e840ea4404ae4dac4d4542f2e7.png'
const TUI_GUANG_BAC_IMG='https://img01.yzcdn.cn/upload_files/2021/07/13/Fg-U6Ne5BmAP1yJ1J34-ykMPornu.jpg'
const TUI_GUANG_BAC_IMG2= 'https://b.yzcdn.cn/mei-pc/81abc11bd66d832617b55e9a37055d77.png'
// const HANDLE_DATA_URL= "http://47.117.1.209:8082/import_yizhige.html"
const HANDLE_DATA_URL= "https://yizhigeapi.yizhige.cn/import_yizhige.html"

// const UPLOAD_BASE_URL = 'https://platform.teamcafe.cn/saas-biz-service/manager/upload'

export {
  AGENCY_NAME,
  MAP_KEY,
  COMPANY_ID,
  UPLOAD_BASE_URL,
  BASE_URL1,
  BASE_URL,
  BASE_URL2,
  RESERVE_START_TIME,
  FORM_EMPTY_ERROR_TIPS,
  SERVE_DEFAULT_IMG,
  TUI_GUANG_BAC_IMG,
  TUI_GUANG_BAC_IMG2,
  HANDLE_DATA_URL
}
