import { request4 } from '@/service/request4'
import { request3 } from '@/service/request3'
import axios from 'axios'
import { BASE_URL2 } from '@/common/constant'
import cache from "@/utils/cache"

// export function getOrderList(obj) {
//   return request4({
//     url: '/manager/order/orders',
//     method: 'post',
//     data: obj
//   })
// }
export function getOrderList(obj) {
  return request4({
    url: '/order/web/getOrderList',
    method: 'post',
    data: obj
  })
}

export function getCardList(obj) {
  return request4({
    url: '/manager/order/card-in-cash',
    method: 'post',
    data: obj
  })
}

export function getRechargeRuleList(obj) {
  return request4({
    url: `/manger/admin/cash-rule`,
    method: 'post',
    data: obj
  })
}

export function addOrder(obj) {
  return request4({
    url: `/manager/order/back-end-make-order`,
    method: 'post',
    data: obj
  })
}

export function saveOrder(obj) {
  return request4({
    url: `/manager/order/back-end-order-items`,
    method: 'post',
    data: obj
  })
}

export function getCardDetail(obj) {
  return request4({
    url: `/manager/card/card-detail`,
    method: 'post',
    data: obj
  })
}

export function getUserAccountBalance(userId) {
  return request4({
    url: `/client/customer/get-user-cash/${userId}`,
    method: 'post'
  })
}

export function getServeCategoryList(obj) {
  return request4({
    url: `/manager/admin/serve-cat`,
    method: 'post',
    data: obj
  })
}

export function getServeList(obj) {
  return request4({
    url: `/manager/admin/serve-list`,
    method: 'post',
    data: obj
  })
}

export function getGoodsCategoryList(obj) {
  return request4({
    url: `/manager/admin/good-cata`,
    method: 'post',
    data: obj
  })
}

export function getGoodsList(cataId) {
  return request4({
    url: `/manager/admin/good-by-cata/${cataId}`,
    method: 'post'
  })
}

export function getCardHandselList(cardId, companyId) {
  return request4({
    url: `/manager/card/card-gift/${cardId}/${companyId}`,
    method: 'post'
  })
}

export function isNewUser(userId) {
  return request4({
    url: `/manager/customer/is-new-user/${userId}`,
    method: 'post'
  })
}

export function getShopTeacher(shopId) {
  return request4({
    url: `/client/customer/shop-emp-list/${shopId}`,
    method: 'post'
  })
}

export function getUserBalance(userId) {
  return request4({
    url: `/manager/customer/new-customer-cash/${userId}`,
    method: 'post'
  })
}

export function userBalancePay(obj) {
  return request4({
    url: `/client/customer/pay-by-cash`,
    method: 'post',
    data: obj
  })
}

// export function getPayQrcode(obj) {
//   return request4({
//     url: `/manager/shouqianba/scan`,
//     method: 'post',
//     data: obj
//   })
// }
//获取二维码
export function getPayQrcode(obj) {
  return request4({
    url: `/order/web/getOrderPayQrCode`,
    method: 'post',
    data: obj
  })
}

// export function getOrderPayState(obj) {
//   return request4({
//     url: `/manager/pay/query`,
//     method: 'post',
//     data: obj
//   })
// }
//获取订单支付状态
export function getOrderPayState(id) {
  return request4({
    url: `/order/web/getOrderPayStatus?id=${id}`,
    method: 'get'
  })
}

export function setUserCard(orderNo) {
  return request4({
    url: `/manager/customer/service-after-buy-card/${orderNo}`,
    method: 'post'
  })
}

export function setUserRecharge(orderNo) {
  return request4({
    url: `/manager/customer/deal-undeal-cash/${orderNo}`,
    method: 'post'
  })
}

export function setWaitAmount(obj) {
  return request4({
    url: `/manager/customer/new-undeal-cash-log`,
    method: 'post',
    data: obj
  })
}

// export function setWaitAmount(obj) {
//   return request4({
//     url: `/client/customer/new-undeal-cash-log`,
//     method: 'post',
//     data: obj
//   })
// }

export function setOrderBack(obj) {
  return request4({
    url: `/manager/order/roll-back-pay`,
    method: 'post',
    data: obj
  })
}

export function getUserInfo(phone) {
  return request4({
    url: `/manager/appointment/is-user-exist/${phone}`,
    method: 'post'
  })
}

export function getOrderDetail(companyId, orderNo) {
  return request4({
    url: `/manager/order/order-detail/${companyId}/${orderNo}`,
    method: 'post'
  })
}

export function getOrderDetailInfo(id) {
  return request4({
    url: `/order/web/getOrder?id=${id}`,
    method: 'get'
  })
}
export function getUserBuyServeQuanyi(serveId, paraId, userId) {
  return request4({
    url: `/client/customer/has-serve-in/${serveId}/${paraId}/${userId}`,
    method: 'post'
  })
}

export function getUserCardList(userId) {
  return request4({
    url: `/manager/order/user-cards/${userId}/0/0`,
    method: 'post'
  })
}

export function getUserDikouList(userId, serveId, paraId) {
  return request4({
    url: `/manager/order/get-dikou-card-info/${userId}/${serveId}/${paraId}`,
    method: 'post'
  })
}

export function setOrderFinsh(obj) {
  return request4({
    url: `/manager/order/finish-app-relate`,
    method: 'post',
    data: obj
  })
}

export function setOrderQuanyiPay(obj) {
  return request4({
    url: `/manager/order/pay-by-benifit`,
    method: 'post',
    data: obj
  })
}

export function setUserOrderBalancePay(obj) {
  return request4({
    url: `/manager/order/pay-by-cash`,
    method: 'post',
    data: obj
  })
}

export function setUserOrderStoredValueCardPay(obj) {
  return request4({
    url: `/manager/customer/pay-by-cash-card-new`,
    method: 'post',
    data: obj
  })
}

export function setKaidanShouDongdk(orderNo) {
  return request4({
    url: `/manager/customer/service-after-buy-serve/${orderNo}`,
    method: 'post'
  })
}

export function addDaiDIkou(obj) {
  return request4({
    url: `/manager/admin/new-dikou-log`,
    method: 'post',
    data: obj
  })
}

export function updateZuheInfo(obj) {
  return request4({
    url: `/manager/order/update-order-cash-set`,
    method: 'post',
    data: obj
  })
}

export function updatePayInfo(obj) {
  return request4({
    url: `/manager/order/update-order-combine-set`,
    method: 'post',
    data: obj
  })
}

export function saomaPay(obj) {
  return request4({
    url: `/manager/shouqianba/pay`,
    method: 'post',
    data: obj
  })
}

export function xianjinPay(obj) {
  return request4({
    url: `/manager/order/pay-by-just-cash`,
    method: 'post',
    data: obj
  })
}

export function xianjinLog(obj) {
  return request4({
    url: `/manager/order/new-paid-cash-log`,
    method: 'post',
    data: obj
  })
}

export function setRecordPaytype(orderNo, payway, empId) {
  return request4({
    url: `/manager/money/new-yeji-item`,
    method: 'post',
    data: {
      orderNo,
      payway,
      empId
    }
  })
}

export function setOrderRecordPaytype(obj) {
  return request4({
    url: `/manager/order/save-pay-way`,
    method: 'post',
    data: obj
  })
}

export function setOrderNo(obj) {
  return request4({
    url: `/manager/order/make-refund-order`,
    method: 'post',
    data: obj
  })
}

export function setRefund(obj) {
  return request4({
    url: `/manager/order/refund`,
    method: 'post',
    data: obj
  })
}

export function cancelOrder(obj) {
  return request4({
    url: `/manager/order/cancel-order`,
    method: 'post',
    data: obj
  })
}


export function setReserveRemark2(obj) {
  return request3({
    url: `/manager/appointment/new-order-memo`,
    method: 'post',
    data: obj
  })
}

export function getOrderPayType(orderNo) {
  return request4({
    url: `/manager/money/order-pay-way/${orderNo}`,
    method: 'post',
  })
}

export function getOrderRefoundList(obj) {
  return request4({
    url: `/manager/money/refunds`,
    method: 'post',
    data: obj
  })
}

export function getOrderRefoundDetail(orderNo) {
  return request4({
    url: `/manager/order/refund-order-detail/${orderNo}`,
    method: 'post'
  })
}

export function getUserCash(userId) {
  return request4({
    url: `/manager/order/tuikuan-user-cash/${userId}`,
    method: 'post'
  })
}

export function getCardInfo(userId, cardNo) {
  return request4({
    url: `/manager/order/tuikuan-user-chuzhi/${userId}/${cardNo}`,
    method: 'post'
  })
}

export function getCommentList(obj) {
  return request4({
    url: `/manager/order/comment-orders`,
    method: 'post',
    data: obj
  })
}
//服务订单退款
export function serveLastRefund(obj) {
  return request4({
    url: `/order/web/serveRefund`,
    method: 'post',
    data: obj
  })
}
//卡项退款
export function cardRefund(obj) {
  return request4({
    url: `/order/web/cardRefund`,
    method: 'post',
    data: obj
  })
}

//xxxx
export function getzekouCardList(userId) {
  return request4({
    url: `/manager/order/get-discount-card/${userId}`,
    method: 'post'
  })
}

//xxxxx
export function zekouCardLog(obj) {
  return request4({
    url: `/manager/order/update-order-promprice`,
    method: 'post',
    data: obj
  })
}
//控制评价展示
export function displayEvaluate(id) {
  return request4({
    url: `/order/web/displayEvaluate?id=${id}`,
    method: 'get'
  })
}
//获取服务订单退款信息
export function getServeRefundInfo(id) {
  return request4({
    url: `/order/web/getServeRefundInfo?id=${id}`,
    method: 'get'
  })
}
//获取卡项订单退款信息
export function getCardRefundInfo(id) {
  return request4({
    url: `/order/web/getCardRefundInfo?id=${id}`,
    method: 'get'
  })
}

export function getTichengList(orderNo) {
  return request4({
    url: `/manager/money/order-yeji-ticheng/${orderNo}`,
    method: 'post'
  })
}

export function getRefundTeacherList(shopId) {
  return request4({
    url: `/manager/admin/get-admin-users/${shopId}`,
    method: 'post'
  })
}

export function refund(obj) {
  return request4({
    url: `/manager/pay/refund`,
    method: 'post',
    data: obj
  })
}

export function setOrderCommission(orderNo,tckind,shopId) {
  return request4({
    url: `/manager/money/cal-emp-serve-ticheng/${orderNo}/${tckind}/${shopId}`,
    method: 'post',
  })
}

export function getTeacherScore(ordeNo) {
  return request4({
    url: `manager/money/order-yeji-ticheng/${ordeNo}`,
    method: 'post',
  })
}


export function setXiaosouYeji(obj) {
  return request4({
    url: `/manager/money/brand-new-sale-yeji`,
    method: 'post',
    data:obj
  })
}

export function setXiaohaoYeji(obj) {
  return request4({
    url: `/manager/money/brand-new-serve-yeji`,
    method: 'post',
    data:obj
  })
}

export function getShopList(cid) {
  let adminInfo = cache.getCache('adminInfo')
  let cashUid = adminInfo.userId
  return request4({
    url: `/manager/permission/shop-basic/${cid}/${cashUid}`,
    method: 'post',
  })
}

export function getShopTeacherList(shopId) {
  return request4({
    url: `/manager/admin/get-simple-emp-list/${shopId}`,
    method: 'post'
  })
}

export function addTicheng(obj) {
  return request4({
    url: `/manager/money/brand-new-sale-ticheng`,
    method: 'post',
    data:obj
  })
}


export function useCoupon(obj) {
  return request4({
    url: `/manager/money/use-coupon`,
    method: 'post',
    data:obj
  })
}

export function updateXjqPayPrice(obj) {
  return request4({
    url: `/manager/order/update-order-coupon-set`,
    method: 'post',
    data:obj
  })
}

export function getOrderYejiList(orderNo) {
  return request4({
    url: `/manager/money/get-order-yeji/${orderNo}`,
    method: 'post',
  })
}

export function updateOrderScore(obj) {
  return request4({
    url: `/manager/money/update-yeji-ticheng`,
    method: 'post',
    data:obj
  })
}

export function getTukuanInfo2(orderNo) {
  return request4({
    url: `/manager/order/get-refund-detail/${orderNo}`,
    method: 'post',
  })
}
//后台支付
export function backgroundPay(obj) {
  return request4({
    url: `/order/web/backgroundPay`,
    method: 'post',
    data:obj
  })
}
//开卡
export function cardPurchase(obj) {
  return request4({
    url: `/order/web/cardPurchase`,
    method: 'post',
    data:obj
  })
}
//关闭收银台回退服务方案抵扣方案
export function orderFallback(id) {
  return request4({
    url: `/order/web/orderFallback?id=${id}`,
    method: 'get',
  })
}

//编辑备注
export function editRemark(obj) {
  return request4({
    url: `/order/web/editRemark`,
    method: 'PUT',
    data:obj
  })
}
//后台输码验券
export function exchangeCoupon(obj) {
  return request4({
    url: `/coupon/web/exchangeCoupon`,
    method: 'post',
    data: obj
  })
}
//后台输码兑换券
export function exchangeUserCoupon(obj) {
  return request4({
    url: `/coupon/web/exchangeUserCoupon`,
    method: 'post',
    data: obj
  })
}
//后台扫码验券
export function scanExchangeCoupon(obj) {
  return request4({
    url: `/coupon/web/scanExchangeCoupon`,
    method: 'post',
    data: obj
  })
}
//开单抵扣金额计算
export function calculatePrice(obj) {
  return request4({
    url: `/appointment/web/calculatePrice`,
    method: 'post',
    data: obj
  })
}
//获取全部的支付方式
export function getAllPayWay() {
  return request4({
    url: `/order/web/getAllPayWay`,
    method: 'get',
  })
}
//获取订单的业绩提成信息
export function getOrderPerformanceCommission(orderId) {
  return request4({
    url: `/order/web/getOrderPerformanceCommission?orderId=${orderId}`,
    method: 'get',
  })
}
//设置订单的业绩提成
export function setOrderPerformanceCommission(obj) {
  return request4({
    url: `/order/web/setOrderPerformanceCommission`,
    method: 'post',
    data: obj
  })
}
//撤单
export function revokeOrder(id) {
  return request4({
    url: `/order/web/revokeOrder?id=${id}`,
    method: 'get',
  })
}
//获取退款用户列表
export function getReturnEmpList(shopId,orderId) {
  return request4({
    url: `/adminUser/web/getReturnEmpList?shopId=${shopId}&orderId=${orderId}`,
    method: 'get',
  })
}

//编辑订单销售人员
export function editOrderSale(data) {
  return request4({
    url: `/order/web/editOrderSale`,
    method: 'post',
    data
  })
}