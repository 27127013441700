import axios from 'axios'
import { showLoading, hideLoading } from '../../common/loading'
import { BASE_URL, TIME_OUT } from './config'
import localCache from '@/utils/cache'
import router from '@/router'
import { ElMessage } from 'element-plus'


// 定义一个缓存池用来缓存数据
let cache = {}
const EXPIRE_TIME = 30000

export function request4(config) {
  // showLoading()
  // 利用axios的cancelToken来取消请求
  const CancelToken = axios.CancelToken

  // 1.创建axios的实例
  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: TIME_OUT,
    retry: 6,
    retryDelay: 1000
  })

  // 2.axios的拦截器
  instance.interceptors.request.use(
    (config) => {
      // hideLoading()
      let adminInfo = localCache.getCache('adminInfo')
      let userId
      if (adminInfo) {
        userId = adminInfo.userId
      }
      const token = localCache.getCache('token')
      if (!adminInfo || !userId) {
        router.push('/login')
        ElMessage.error('请先登录!')
        return
      }

      try {
        let value = document.querySelector("meta[name='_csrf']")?.content
        let key = document.querySelector("meta[name='_csrf_header']")?.content
        if (key && value) {
          config.headers[key] = value
        }
        if (userId) {
          config.headers['User-Id'] = userId
        }
        config.headers['token-salt'] = localCache.getCache('tokenSalt')
      } catch (error) {
        // console.log(error)
      }

      // 如果需要缓存--考虑到并不是所有接口都需要缓存的情况
      if (config.cache) {
        let source = CancelToken.source()
        config.cancelToken = source.token
        // 去缓存池获取缓存数据
        let data = cache[config.url]
        // 获取当前时间戳
        let expire_time = new Date().getTime()
        // 判断缓存池中是否存在已有数据 存在的话 再判断是否过期
        // 未过期 source.cancel会取消当前的请求 并将内容返回到拦截器的err中
        if (data && expire_time - data.expire < EXPIRE_TIME) {
          source.cancel(data)
        }
      }

      return config
    },
    (err) => {
      // hideLoading()
      console.log(err)
    }
  )

  // 2.2.响应拦截
  instance.interceptors.response.use(
    (response) => {
      // hideLoading()
      if (response.config.cache) {
        // 缓存数据 并将当前时间存入 方便之后判断是否过期
        let data = {
          expire: new Date().getTime(),
          data: response.data
        }
        cache[`${response.config.url}`] = data
      }
      return response.data
    },
    (error) => {
      // hideLoading()
      var config = error.config
      let status = error.response && error.response.status
      if (status == 401) {
        router.push('/login')
        localCache.clearCache()
        return
      }
      if (status == 500 || status == 404) {
        config.__retryCount = 6
        ElMessage.error('服务器内部错误,请联系管理员！')
      }

      // 请求拦截器中的source.cancel会将内容发送到error中
      // 通过axios.isCancel(error)来判断是否返回有数据 有的话直接返回给用户
      if (axios.isCancel(error)) {
        return Promise.resolve(error.message.data)
      }        // 如果没有的话 则是正常的接口错误 直接返回错误信息给用户
      return Promise.reject(error)
    }
  )

  // 3.发送真正的网络请求
  return instance(config)
  // 此处返回promis对象
}
