import { request } from '../request'
import axios from 'axios'
import qs from 'qs'
import { BASE_URL, BASE_URL2 } from '@/common/constant'
import { request5 } from '@/service/request5'
import { request4 } from '@/service/request4'

// export function accountLogin(obj) {
//   return axios({
//     url: `${BASE_URL2}/manager/login`,
//     method: 'post',
//     data: obj
//   })
// }

export function accountLogin(obj) {///manager/login
  return request5({
    url: `/manager/admin/admin-user-login`,
    method: 'post',
    data: obj
  })
}

// export function getCode() {
//   return axios({
//     url: `/manager/login`,
//     method: 'post',
//   })
// }

export function getCode() {
  return request5({
    url: `/manager/login`,
    method: 'post'
  })
}

// export function getYanzhengCode() {
//   return axios({
//     url: `${BASE_URL2}/captcha`,
//     method: 'post'
//   })
// }

export function getYanzhengCode() {
  return request5({
    url: `/captcha`,
    method: 'get'
  })
}

// export function getPhoneCode(obj) {
//   return axios({
//     url: `${BASE_URL2}/manager/admin/send-sms-code`,
//     method: 'post',
//     data: obj
//   })
// }
//发送验证码
export function getPhoneCode(obj) {
  return request5({
    url: `/login/applet/sendSmsCode`,
    method: 'post',
    data: obj,
    isNoHasInfo:true
  })
}
//重置密码
export function resetPassword(obj) {
  return request5({
    url: `/adminUSer/applet/resetPassword`,
    method: 'post',
    data: obj
  })
}
//首次登录编辑密码
export function editPassword(obj) {
  return request4({
    url: `/adminUSer/applet/editPassword`,
    method: 'post',
    data: obj
  })
}
