import { accountLogin, getYanzhengCode } from '@/service/login'
import { ElMessage } from 'element-plus'
import router from '@/router'
import cache from '@/utils/cache'
import { getUserResourceList,getUserButtonTagList } from "@/service/main/staff"
import { mapMenusToRoutes } from '@/utils/map-menus'
// import { userMenus } from '@/common/config/usermenus'

const loginModule = {
  namespaced: true,
  state() {
    return {
      captcha: '',
      adminInfo: {
        userRealname: '李然',
        userMobile: '13965432345',
        userId: 238,
        shopId: 10079,
        companyId: 10000,
        levelName: '初级理疗师',
        rolelist: [
          {
            roleId: 5,
            roleName: '测试管理员'
          }
        ]
      }
    }
  },
  getters: {},
  mutations: {
    changeYanzhengcode(state, captcha) {
      state.captcha = captcha
    }
  },
  actions: {
    async getYanzhengCodeAction({ commit }, payload) {
      const res = await getYanzhengCode()
      // console.log(res);
      commit('changeYanzhengcode', res.data)
    },
    async accountLoginAction({ commmit, state }, payload) {
      // console.log(payload);
      try {
        const res = await accountLogin({
          userMobile: payload.username,
          userPassword: payload.password,
          captcha: payload.code,
          token: payload.token
        })

        if (res.code == 0) {
          // cache.setCache('adminInfo', state.adminInfo)
          // console.log('用户信息',res.data)
          cache.setCache('adminInfo', res.data)
          cache.setCache('username', payload.username)
          cache.setCache('password', payload.password)
          cache.setCache('currentAdminName', res.data.userRealname)
          cache.setCache('tokenSalt', res.data.tokenSalt)
          getUserResourceList(res.data.userId).then(v=>{
            cache.setCache('authRoutes', v.data)
            ElMessage.success({
              message: '登录成功!',
              type: 'success'
            })
            const { data } = v
            if(data && data.length){
              const route = mapMenusToRoutes(data)
              route.forEach((route) => {
                router.addRoute('main', route)
              })
              if(data[0].children.length){
                router.push(data[0].children[0].url)
              }else{
                router.push(data[0].url)
              }
            }
          })
          let buttonData = await getUserButtonTagList()
          cache.setCache('tagList', buttonData.data)
        } else {
          ElMessage.warning({
            message: res.msg,
            type: 'warning'
          })
        }
      } catch (error) {
        // console.log(error)
      }
    }
  }
}

export default loginModule
